import Chart from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

function BarChart({ chartData }) {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    
    const chartOptions = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    if (chartRef.current && chartData) { // Check if chartData is available
      chartInstanceRef.current = new Chart(chartRef.current, {
        type: 'bar',
        data: chartData,
        options: chartOptions,
      });
    }
  }, [chartData]); // Trigger effect when chartData changes

  return <canvas ref={chartRef} />;
}

export default BarChart;