import { Box, Button, TextField, TextareaAutosize } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { packageCategoriesAddUpdateUrl } from "../../../constants/globalUrls";
import UseServices from "../../../services/api"
import { toast } from "react-toastify";


const FormData = (props) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const {currentObj, modeSetUp,loadService,editPermission} = props 
    // console.log("c", props)
    const initialValues = {
        id: currentObj._id,
        title: currentObj.title,
    }; 
    
    
    const handleFormSubmit = async (postData) => {
        let id = toast.loading("Please wait...",{theme:"dark"})
        const response = await UseServices.fetchPostData(packageCategoriesAddUpdateUrl, postData);
        const responseData = response;
        if(response.status === 'valid'){
            // toast.success(responseData.message)
            toast.update(id, { render: responseData.message,autoClose:"3000",theme:"dark", type: "success", isLoading: false });
            loadService(true);
            modeSetUp("list");
        }else{
            toast.error(responseData.message)
        }
        console.log(responseData.message);
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >
                        <input  type="hidden" value={initialValues._id} />
                        <TextField  InputLabelProps={{
                                style: { color: "white" }
                            }}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            name="title"
                            error={!!touched.title && !!errors.title}
                            helperText={touched.title && errors.title}
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="center" mt="20px">
                        <Button type="button" onClick={() => modeSetUp("list")} color="info" variant="contained">
                            Back
                        </Button> &nbsp;&nbsp;
                        {/* <Button type="submit" color="secondary" variant="contained">
                            Submit   
                        </Button> */}

                        {editPermission && <>
                            <Button type="submit" color="secondary" variant="contained">
                            Submit   
                        </Button>
                        </>}
                    </Box>
                </form>
            )}
        </Formik>
    );
};

const checkoutSchema = yup.object().shape({
    title: yup.string().required("required"),

});


export default FormData;
