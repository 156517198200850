import React, { useEffect, useRef, useState } from "react";
import { albedo, days, esc } from "./constants";
import "./graph.css";
import Redrawgraph from "./redrawGraph";
// import * as ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import axios from "axios";
import { climateJSONUrl } from "../../constants/globalUrls";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const DataVisualisation = () => {
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(climateJSONUrl);
      console.log("response", response.status);
      setIsLoading(false);
      if (response.status === 200) {
        setCities(response.data);
        // console.log("clm", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      // toast.error("An error occurred while submitting the form");
      console.log(error);
      // alert("An error occurred");
    }
  };

  const [solarData, setSolarData] = useState("");
  const selectCountryRef = useRef(null);
  const selectCityRef = useRef(null);
  const cityDetailsRef = useRef(null);
  const solarDetailsRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [angularData, setAngularData] = useState(null);

  useEffect(() => {
    if (cities.length === 0) return;
    const selectCountry = selectCountryRef.current;
    let time;
    cities.sort((a, b) => {
      return a.location < b.location ? -1 : 1;
    });

    cities.forEach((c) => {
      c.location =
        c.location.charAt(0).toUpperCase() + c.location.slice(1).toLowerCase();
    });

    let countryList = new Set(cities.map((c) => c.country));
    countryList = [...countryList].sort((a, b) => (a < b ? -1 : 1));

    countryList.forEach((c) => {
      // create option using DOM
      const newOption = document.createElement("option");
      const optionText = document.createTextNode(c);
      // set option text
      newOption.appendChild(optionText);
      // and option value
      newOption.setAttribute("value", c);
      selectCountry.appendChild(newOption);
    });

    // Fetch angularData here and set it in state
    const fetchData = async () => {
      const data = await getAngularData();
      setAngularData(data);
    };
    fetchData();
  }, [cities]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedCity("");

    const cityList = cities.filter((c) => c.country === event.target.value);

    const selectCity = selectCityRef.current;
    if (selectCity) {
      selectCity.innerHTML = ""; // Clear existing options
    }

    cityList.forEach((c) => {
      // create option using DOM
      const newOption = document.createElement("option");
      const optionText = document.createTextNode(c.location);
      // set option text
      newOption.appendChild(optionText);
      // and option value
      newOption.setAttribute("value", c.location);
      selectCity.appendChild(newOption);
    });
  };

  const handleCityChange = (event) => {
    const loader = document.getElementById("loader");
    if (loader) {
      loader.style.display = "block";
    }
    setTimeout(() => {
      setSelectedCity(event.target.value);

      const city = cities.find((c) => c.location === event.target.value);
      if (city) {
        cityDetailsRef.current.innerHTML = "";
        solarDetailsRef.current.innerHTML = "";

        const detailsTable = document.createElement("table");
        detailsTable.classList.add("details");
        detailsTable.innerHTML = `
      <tr>
        <th>Location</th>
        <th>Country</th>
        <th>Latitude</th>
        <th>Longitude</th>
        <th>Time Zone</th>
      </tr>
      <tr>
        <td>${city.location}</td>
        <td>${city.country}</td>
        <td>${city.latitude}</td>
        <td>${city.longitude}</td>
        <td>${city.timeZone}</td>
      </tr>
    `;

        cityDetailsRef.current.appendChild(detailsTable);

        calculateSolar(city).then((solar) => {
          console.log("solar", solar);
          setSolarData(solar);
          redrawGraphComponent(solar);

          // const redrawgraphComponent = <Redrawgraph solarData={solar} />
          // ReactDOM.render(redrawgraphComponent, solarDetailsRef.current);

          const solarTable = document.createElement("table");
          solarTable.classList.add("details");
          solarTable.innerHTML = `
        <tr>
          <th>Month</th>
          <th>${Object.keys(directions).join("</th><th>")}</th>
          <th>Top</th>
        </tr>
        ${solar
          .map((mon, id) => {
            return (
              "<tr>" +
              "<td>" +
              months[id] +
              "</td>" +
              "<td>" +
              mon.map((v) => v.toFixed(2)).join("</td><td>") +
              "</td>" +
              "</tr>"
            );
          })
          .join()}
      `;

          solarDetailsRef.current.appendChild(solarTable);
          // document.getElementById("loader").style.display ="none"
          if (loader) {
            loader.style.display = "none";
          }
        });

        const redrawGraphComponent = (solar) => {
          const container = document.createElement("div"); // Create a new container element
          solarDetailsRef.current.innerHTML = ""; // Clear the existing content

          const redrawgraphComponent = <Redrawgraph solarData={solar} />;
          createRoot(container).render(redrawgraphComponent);
          // ReactDOM.render(redrawgraphComponent, container); // Render the component into the container

          const solarDetailsNode = solarDetailsRef.current;
          while (solarDetailsNode.firstChild) {
            solarDetailsNode.firstChild.remove(); // Remove any existing children from solarDetailsRef.current
          }

          solarDetailsNode.appendChild(container); // Append the container to solarDetailsRef.current
        };
      }
    }, 1000);
  };

  let toDeg = 180 / Math.PI;
  let toRad = 1 / toDeg;

  const directions = {
    NE: -135,
    E: -90,
    SE: -45,
    S: 0,
    SW: 45,
    W: 90,
    NW: 135,
    N: 180,
  };

  const calculateSolar = (city) => {
    return new Promise((resolve) => {
      let results = [];
      let taub = Object.values(city.taub);
      let taud = Object.values(city.taud);

      let taubVector = annualInterpolate(taub);
      let taudVector = annualInterpolate(taud);

      let lat =
        (city.latitude.slice(-1) === "S" ? -1 : 1) *
        city.latitude.slice(0, city.latitude.length - 1).valueOf();
      let lon =
        (city.longitude.slice(-1) === "W" ? -1 : 1) *
        city.longitude.slice(0, city.longitude.length - 1).valueOf();
      let tmz = city.timeZone.valueOf();

      let LSM = 15 * tmz;

      for (let month = 0; month < 12; month++) {
        let monthAvg = [];
        let top = [];
        let vertical = [[], [], [], [], [], [], [], []];

        for (let day = 1; day <= days[month]; day++) {
          let d = dayInYear(month, day);

          for (let section = 0; section < 24; section += 0.25) {
            let AST =
              section + angularData[d - 1].equationTime / 60 + (lon - LSM) / 15;
            let hourAng = 15 * (AST - 12);

            let elevAng =
              toDeg *
              Math.asin(
                Math.cos(toRad * lat) *
                  Math.cos(toRad * angularData[d - 1].declinationAng) *
                  Math.cos(toRad * hourAng) +
                  Math.sin(toRad * lat) *
                    Math.sin(toRad * angularData[d - 1].declinationAng)
              );

            let sinFi =
              (Math.sin(toRad * hourAng) *
                Math.cos(toRad * angularData[d - 1].declinationAng)) /
              Math.cos(toRad * elevAng);
            let cosFi =
              (Math.cos(toRad * hourAng) *
                Math.cos(toRad * angularData[d - 1].declinationAng) *
                Math.sin(toRad * lat) -
                Math.sin(toRad * angularData[d - 1].declinationAng) *
                  Math.cos(toRad * lat)) /
              Math.cos(toRad * elevAng);

            let azimuth =
              toDeg * (sinFi < 0 ? Math.asin(sinFi) : Math.acos(cosFi));

            let airMass =
              1 /
              (Math.sin(toRad * elevAng) +
                0.50572 * Math.pow(6.07995 + elevAng, -1.6364));

            if (isNaN(airMass)) continue;

            let ab =
              1.454 -
              0.406 * taubVector[d - 1] -
              0.268 * taudVector[d - 1] +
              0.021 * taubVector[d - 1] * taudVector[d - 1];
            let eb =
              angularData[d - 1].radiantFlux *
              Math.exp(-taubVector[d - 1] * Math.pow(airMass, ab));

            if (isNaN(eb)) continue;

            let ad =
              0.507 +
              0.205 * taubVector[d - 1] -
              0.08 * taudVector[d - 1] -
              0.19 * taubVector[d - 1] * taudVector[d - 1];
            let ed =
              angularData[d - 1].radiantFlux *
              Math.exp(-taudVector[d - 1] * Math.pow(airMass, ad));

            if (isNaN(ed)) continue;

            let anistropy = eb / angularData[d - 1].radiantFlux;

            if (isNaN(anistropy)) continue;

            let t = getHorizontal(elevAng, eb, ed, anistropy);

            if (!isNaN(t)) top = [...top, t];

            Object.keys(directions).forEach((k, id) => {
              let v = getByDirection(
                directions[k],
                azimuth,
                elevAng,
                eb,
                ed,
                anistropy
              );

              if (!isNaN(v)) {
                vertical[id] = [...vertical[id], v];
              }
            });
          }

          //reportProcessTime(d)
        }

        vertical.forEach(
          (direc) =>
            (monthAvg = [
              ...monthAvg,
              direc.reduce((a, b) => a + b, 0) / (days[month] * 96),
            ])
        );
        monthAvg = [
          ...monthAvg,
          top.reduce((a, b) => a + b, 0) / (days[month] * 96),
        ];

        results = [...results, monthAvg];
      }

      resolve(results);
    });
  };

  const getHorizontal = (elevAng, eb, ed, anistropy) => {
    const segma = 0;

    let thetaTop = 90 - elevAng;

    //redundant condition
    let rb =
      elevAng >= 0 && thetaTop <= 90
        ? Math.cos(toRad * thetaTop) / Math.sin(toRad * elevAng)
        : 0;

    let a = Math.cos(toRad * thetaTop);
    let etb = a > 0 ? a * eb : 0;

    //redundant calculations: segma is already 0, which makes the second have = 1;
    let etd =
      ed *
      (anistropy * rb + ((1 - anistropy) * (1 + Math.cos(toRad * segma))) / 2);

    //same here
    let etr =
      ((eb * Math.sin(toRad * elevAng) + ed) *
        elevAng *
        (1 - Math.cos(toRad * segma))) /
      2;

    let et = etb + etd + etr;

    return et;
  };

  const getByDirection = (surAzimuth, azimuth, elevAng, eb, ed, anistropy) => {
    const segma = 90;
    let gamma = azimuth - surAzimuth;

    //redundant calcs
    let theta =
      toDeg *
      Math.acos(
        Math.cos(toRad * elevAng) *
          Math.cos(toRad * gamma) *
          Math.sin(toRad * segma) +
          Math.sin(toRad * elevAng) * Math.cos(toRad * segma)
      );

    let rb =
      elevAng >= 0 && theta <= 90
        ? Math.cos(toRad * theta) / Math.sin(toRad * elevAng)
        : 0;

    let a = Math.cos(toRad * theta);
    let etb = a > 0 ? eb * a : 0;

    let etd =
      ed *
      (anistropy * rb + ((1 - anistropy) * (1 + Math.cos(toRad * segma))) / 2);

    let etr =
      ((eb * Math.sin(toRad * elevAng) + ed) *
        albedo *
        (1 - Math.cos(toRad * segma))) /
      2;

    let et = etb + etd + etr;

    return et;
  };

  const annualInterpolate = (monthlyData) => {
    let arr = [];
    let shifted;

    for (let m = 0; m < 12; m++) {
      let preM = m == 0 ? 11 : m - 1;
      let v = (monthlyData[m] - monthlyData[preM]) / days[preM];

      for (let d = 0; d < days[preM]; d++) {
        let dayValue = parseFloat(d * v) + parseFloat(monthlyData[preM]);
        arr = [...arr, dayValue];
      }
    }

    //ten days shift
    shifted = dayInYear(11, 31) - dayInYear(11, 21) + 1;
    let part = arr.splice(0, shifted);
    arr = [...arr, ...part];

    return arr;
  };

  const dayInYear = (month, day) => {
    let totalDays;
    totalDays = day;
    for (let i = 0; i < month; i++) {
      totalDays += days[i];
    }

    return totalDays;
  };

  const e0 = (day) => {
    return esc * (1 + 0.033 * Math.cos((2 * Math.PI * (day - 3)) / 365));
  };

  const declinationAng = (day) => {
    return 23.45 * Math.sin(((2 * Math.PI) / 365) * (day + 284));
  };

  const et = (day) => {
    let etRad = ((2 * Math.PI) / 365) * (day - 1);

    return (
      2.2918 *
      (0.0075 +
        0.1868 * Math.cos(etRad) -
        3.2077 * Math.sin(etRad) -
        1.4615 * Math.cos(2 * etRad) -
        4.089 * Math.sin(2 * etRad))
    );
  };

  const getAngularData = () => {
    return new Promise((resolve) => {
      let day = 0;
      let arr = [];
      for (let m = 0; m < 12; m++) {
        for (let d = 1; d <= days[m]; d++) {
          day++;
          arr = [
            ...arr,
            {
              radiantFlux: e0(day),
              declinationAng: declinationAng(day),
              equationTime: et(day),
            },
          ];
        }
      }

      resolve(arr);
    });
  };

  return (
    <div style={{ textAlign: "center", marginTop: "30px" }}>
      <label>Select Country </label>
      <select
        id="country"
        style={{width:"350px",height:"40px"}}
        ref={selectCountryRef}
        onChange={handleCountryChange}
      ></select>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <label>Select City / Location </label>
      <select
        id="city"
        style={{width:"350px",height:"40px"}}
        ref={selectCityRef}
        onChange={handleCityChange}
      >
        <option disabled>Select City / Location</option>
      </select>
      <div className="row">
        <div id="cityDetails" ref={cityDetailsRef}></div>
        <div id="solarDetails" ref={solarDetailsRef}></div>
      </div>
      <div id="loader" className="loader"></div>
    </div>
  );
};
