import { Autocomplete, Box, Button, Grid, TextField, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DeleteIcon from '@mui/icons-material/Delete';
import Header from "../../components/Header";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useRef, useState } from "react";
import { packageSubCategoriesListUrl, packageSubCategoriesDeleteUrl } from "../../constants/globalUrls";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { toast } from "react-toastify";
import FormData from "./components/form_component";
import UseServices from "../../services/api";

const PackageSubCategories = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dataFetchedRef = useRef(false);
    const [currentMode, setCurrentMode] = useState("list")  //# view ,list, edit 
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState([])
    const [resultTitle, setTitleResult] = useState([])
    const [currentObj, setCurrentObj] = useState({
        "title": "", "_id": "", "id": ""
    })

    const permissions = Object.freeze({
        add: true,
        edit: true,
        view: true, // List of data must be true for other permissions
        delete: true

    })



    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        // fetchRequests();
        loadService(true);
    }, [])
    const deleteRecordNow = async (rowId) => {
        let id = toast.loading("Please wait...")
        const response = await UseServices.fetchPostData(packageSubCategoriesDeleteUrl, {
            "id": rowId
        });
        const responseData = response;
        if (response.status === 'valid') {
            // toast.success(responseData.message)
            toast.update(id, { render: responseData.message, autoClose: "3000", type: "success", isLoading: false });
            loadService(true);
        } else {
            toast.error(responseData.message)
        }
        console.log(responseData.message);
    }


    const fetchRequests = async (title = "", sub_title = "") => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                packageSubCategoriesListUrl,
                JSON.stringify({ access_token: localStorage.getItem("access_token"), "title": title, "sub_title": sub_title }),
                {headers: {'Content-Type': 'application/json'}}
            );
            // console.log("response", response.status);
            setIsLoading(false);
            if (response.status === 200) {
                console.log("response", response.data.data)
                setResult(response.data.data);
            } else {
                toast.error("Something error occured. Try again..!");
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while submitting the form");
            console.log(error);
        }
    };

    const editFunClick = (data) => {
        // console.log(data);
        setCurrentObj(data);
        setCurrentMode("edit");
    }
    const loadService = (isLoa = false) => {
        // alert(isLoa)
        if (isLoa) {
            fetchRequests();
        }
    }
    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "category",
            headerName: "Categoty Name",
            flex: 1,
            valueGetter: (params) =>
                `${params.row.category.title || ''}`,
        },
        { field: "title", headerName: "Name", flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>

                        {permissions.edit && <>
                            <Button onClick={() => editFunClick(params.row)} variant="contained" color="success" ><BorderColorIcon /></Button>
                        </>}
                        &nbsp;&nbsp;
                        {permissions.delete && <>
                            <Button onClick={() => {
                                if (
                                    window.confirm(
                                        'Are you sure Delete this "' +
                                        params.row.title +
                                        '" Package Category?'
                                    )
                                ) {
                                    deleteRecordNow(
                                        params.row._id
                                    );
                                }
                            }} variant="contained" color="error">
                                <DeleteIcon />
                            </Button>
                        </>}

                    </>

                );
            },
        },
    ];

    return (
        <>
            {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(currentObj, null, 2)}
                  </pre> */}
            {(currentMode === "edit" || currentMode === "view") && <>
                <Box m="20px">
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <Header title="Edit Sub Category Page" subtitle="View / Edit the Package Sub Category" />
                        </Grid>
                        <Grid item xs={5}>
                        </Grid>


                    </Grid>
                    <Box
                        m="0px 0 0 0"
                        height="70vh"
                    >
                        <FormData editPermission={permissions.edit} loadService={loadService} currentObj={currentObj} modeSetUp={setCurrentMode} />
                    </Box>
                </Box>
            </>}
            {currentMode === "list" &&
                <Box m="20px">
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <Header title="Sub Category" subtitle="Managing the Package Sub Category  List" />
                        </Grid>
                        <Grid item xs={1}>

                            {permissions.add && <>
                                <Button onClick={() => {
                                    setCurrentMode("view");
                                    setCurrentObj({});
                                }} startIcon={<AddIcon sx={{color: theme.palette.text.primary }} />} variant="outlined" color="success"> <span style={{color: theme.palette.text.primary }}>Add</span> </Button>
                            </>}

                        </Grid>


                    </Grid>
                    <Box
                        m="0px 0 0 0"
                        height="70vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                            '& .MuiCircularProgress-root': {
                                color: 'green',
                            },
                        }}
                    >
                        <DataGrid loading={isLoading} rows={result} columns={columns} density={"comfortable"}
                            components={{ Toolbar: GridToolbar }} />
                    </Box>
                </Box>}
        </>


    );
}


export default PackageSubCategories;