import React from 'react'
import { useEffect } from 'react';
import {  useNavigate } from "react-router-dom";

 const LogoutHelper = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        localStorage.clear();
        navigate("/")
    },[])
    
  return  <><h1>Logout...</h1></> 
}

export default LogoutHelper;