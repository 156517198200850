import { Suspense, lazy, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";

// import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import { ProfileEdit } from "./pages/profile";

import LogoutHelper from "./constants/logout_helper";
import Error from "./pages/error";
import UserManual from "./pages/user_manual";
import PackageCategories from "./pages/packages_categories";
import PackageSubCategories from "./pages/packages_sub_categories";
import Projects from "./pages/projects";
import ViewProjectDetails from "./pages/projects/components/viewdetails";
import PaymentDetails from "./pages/transactions/components/paymentdetails";
// import BarChart from "./components/BarChart";
import Chart from "./pages/charts";
import CustomerDetails from "./pages/customers/components/viewcustomerdetails";
import Reports from "./pages/reports";
import ContactEnquire from "./pages/landingpage/contactenquiries";
import NewsLetter from "./pages/landingpage/newsletter";
import GatewaySettings from "./pages/paymentgateway_settings/paymentsettings";
import NewResult from "./pages/newresultpage";
import { DataVisualisation } from "./pages/weatherdata";
const LandingPageContent = lazy(() => import("./pages/landingpage"));
const LandingPageProjects = lazy(() => import("./pages/landingpage/projects"));
const LandingPageFeatures = lazy(() =>
  import("./pages/landingpage/features/index")
);

const Dashboard = lazy(() => import("./scenes/dashboard"));
const Countries = lazy(() => import("./pages/countries"));
const LocationsList = lazy(() => import("./pages/cities"));
const Customers = lazy(() => import("./pages/customers"));
const Subscriptions = lazy(() => import("./pages/subscriptions"));
const CmsPages = lazy(() => import("./pages/cms_pages"));
const Faqs = lazy(() => import("./pages/faqs"));
const Staff = lazy(() => import("./pages/staff"));
const Roles = lazy(() => import("./pages/roles"));
const Login = lazy(() => import("./pages/login"));
const Privileges = lazy(() => import("./pages/privileges"));
const Feedbacks = lazy(() => import("./pages/feedbacks"));
const SupportRequests = lazy(() => import("./pages/support_requests"));
const Transactions = lazy(() => import("./pages/transactions"));
const Payments = lazy(() => import("./pages/payments"));
const SubscriptionUsers = lazy(() => import("./pages/subscription_users"));
import Modules from './pages/modules';

export const WithSideBarAndTopBar = ({ children }) => {
  if (localStorage.getItem("adminIsLogin")) {
    return (
      <>
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            {children}
            {/* {} */}
          </main>
        </div>
      </>
    );
  }

  return <Navigate to="/" />;
};

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const title = path.replace(/^\//, "");
    document.title = `Arcadia | ${
      title.charAt(0).toUpperCase() + title.slice(1)
    }`;
  }, [path]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <WithSideBarAndTopBar>
                  <Dashboard />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/countries"
              element={
                <WithSideBarAndTopBar>
                  <Countries />
                </WithSideBarAndTopBar>
              }l
            />
            <Route
              path="/locations"
              element={
                <WithSideBarAndTopBar>
                  <LocationsList />
                </WithSideBarAndTopBar>
              }
            />

<Route
              path="/weather"
              element={
                <WithSideBarAndTopBar>
                  <DataVisualisation />
                </WithSideBarAndTopBar>
              }
            />


<Route
              path="/contactenquires"
              element={
                <WithSideBarAndTopBar>
                  <ContactEnquire />
                </WithSideBarAndTopBar>
              }
            />

<Route
              path="/newsletter"
              element={
                <WithSideBarAndTopBar>
                  <NewsLetter />
                </WithSideBarAndTopBar>
              }
            />
            
            <Route
              path="/customers"
              element={
                <WithSideBarAndTopBar>
                  <Customers />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/packages"
              element={
                <WithSideBarAndTopBar>
                  <Subscriptions />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/packages-categories"
              element={
                <WithSideBarAndTopBar>
                  <PackageCategories />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/packages-sub-categories"
              element={
                <WithSideBarAndTopBar>
                  <PackageSubCategories />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/privileges"
              element={
                <WithSideBarAndTopBar>
                  <Privileges />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/subscription-users"
              element={
                <WithSideBarAndTopBar>
                  <SubscriptionUsers />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/result"
              element={
                <WithSideBarAndTopBar>
                  <NewResult />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/payments"
              element={
                <WithSideBarAndTopBar>
                  <Payments />
                </WithSideBarAndTopBar>
              }
            /> 
            <Route
              path="/paymentgateway-settings"
              element={
                <WithSideBarAndTopBar>
                  <GatewaySettings />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/projects"
              element={
                <WithSideBarAndTopBar>
                  <Projects />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/details"
              element={
                <WithSideBarAndTopBar>
                  <ViewProjectDetails />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/paymentdetails"
              element={
                <WithSideBarAndTopBar>
                  <PaymentDetails />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/customerdetails"
              element={
                <WithSideBarAndTopBar>
                  <CustomerDetails />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/payments-transactions"
              element={
                <WithSideBarAndTopBar>
                  <Transactions />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/cms"
              element={
                <WithSideBarAndTopBar>
                  <CmsPages />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/usermanual"
              element={
                <WithSideBarAndTopBar>
                  <UserManual />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/faq"
              element={
                <WithSideBarAndTopBar>
                  <Faqs />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/landingpagecontent"
              element={
                <WithSideBarAndTopBar>
                  <LandingPageContent />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/landingpage_projects"
              element={
                <WithSideBarAndTopBar>
                  <LandingPageProjects />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/landingpage_features"
              element={
                <WithSideBarAndTopBar>
                  <LandingPageFeatures />
                </WithSideBarAndTopBar>
              }
            />

            <Route
              path="/staff"
              element={
                <WithSideBarAndTopBar>
                  <Staff />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/roles"
              element={
                <WithSideBarAndTopBar>
                  <Roles />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/support"
              element={
                <WithSideBarAndTopBar>
                  <SupportRequests />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/feedbacks"
              element={
                <WithSideBarAndTopBar>
                  <Feedbacks />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/profile"
              element={
                <WithSideBarAndTopBar>
                  <ProfileEdit />
                </WithSideBarAndTopBar>
              }
            />
            {/* <Route
              path="/bar"
              element={
                <WithSideBarAndTopBar>
                  <Bar />
                </WithSideBarAndTopBar>
              }
            /> */}
            <Route
              path="/charts"
              element={
                <WithSideBarAndTopBar>
                  <Chart />
                </WithSideBarAndTopBar>
              }
            />

            <Route
              path="/reports"
              element={
                <WithSideBarAndTopBar>
                  <Reports />
                </WithSideBarAndTopBar>
              }
            />
            <Route
              path="/privilages_list"
              element={
                <WithSideBarAndTopBar>
                  <Modules />
                </WithSideBarAndTopBar>
              }
            />
            

            <Route path="/logout" element={<LogoutHelper />} />
            <Route path="*" element={<Error />} />
            {/* <Route path="/pie" element={<Pie />} /> */}
            {/* <Route path="/team" element={<WithSideBarAndTopBar> <Team /></WithSideBarAndTopBar>} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} /> */}
          </Routes>
        </Suspense>

        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
