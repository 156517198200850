// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

select {
    font-size: large;
    
}
.name{
    color: rgba(255, 255, 255, 0.082)
}

table.details {
    border-collapse: collapse;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid black;
    background: steelblue;
    margin: 10px;
    padding: 5px;
    color: white;
    width: 85%;
    margin-inline: auto;
    justify-self: stretch;
    margin-bottom: 50px;
}

td {
    padding: 5px;
    border: 1px solid black;
}

th {
    padding: 5px;
    border: 1px solid black;
}

.myCircle:hover {
    stroke: black;
}

.loader {
    display: none;
    background-color: black;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
}

.verticalContainer {
    display:flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/weatherdata/graph.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;;AAEpB;AACA;IACI;AACJ;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;IAC3B,8BAA8B;IAC9B,uBAAuB;IACvB,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,mBAAmB;IACnB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":["section {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 20px;\r\n}\r\n\r\nselect {\r\n    font-size: large;\r\n    \r\n}\r\n.name{\r\n    color: rgba(255, 255, 255, 0.082)\r\n}\r\n\r\ntable.details {\r\n    border-collapse: collapse;\r\n    box-sizing: border-box;\r\n    -moz-box-sizing: border-box;\r\n    -webkit-box-sizing: border-box;\r\n    border: 1px solid black;\r\n    background: steelblue;\r\n    margin: 10px;\r\n    padding: 5px;\r\n    color: white;\r\n    width: 85%;\r\n    margin-inline: auto;\r\n    justify-self: stretch;\r\n    margin-bottom: 50px;\r\n}\r\n\r\ntd {\r\n    padding: 5px;\r\n    border: 1px solid black;\r\n}\r\n\r\nth {\r\n    padding: 5px;\r\n    border: 1px solid black;\r\n}\r\n\r\n.myCircle:hover {\r\n    stroke: black;\r\n}\r\n\r\n.loader {\r\n    display: none;\r\n    background-color: black;\r\n    opacity: 0.6;\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    z-index: 1001;\r\n}\r\n\r\n.verticalContainer {\r\n    display:flex; \r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
