import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridSearchIcon, GridToolbar } from "@mui/x-data-grid";
import UseServices from "../../services/api";
import { tokens } from "../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState, useRef } from "react";
import {
  customersDeleteUrl,
  customersListUrl,
  deleteProjectListUrl,
  projectListUrl,
} from "../../constants/globalUrls";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import LoopIcon from "@mui/icons-material/Loop";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { useNavigate } from "react-router-dom";
import { checkPermission, getAccessToken } from "../../constants/helpers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
const Projects = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentMode, setCurrentMode] = useState("list"); //# view ,list, edit
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentStatus, setcurrentStatus] = useState("");
  const [searchkey, setSearchkey] = useState("");
  const [formData, setFormdata] = useState({
    from_date: "",
    to_date: "",
    current_stauts: "",
    search_key: "",
    access_token: getAccessToken(),
  });

  function formatDate(date) {
    if (!date) return ""; // Handle case when no date is selected
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = formattedDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleInputChangeFromDates = (date) => {
    const formattedDate = formatDate(date);
    setFormdata((prevFormData) => ({
      ...prevFormData,
      from_date: formattedDate,
    }));
  };

  const handleInputChangeToDates = (date) => {
    const formattedDate = formatDate(date);
    setFormdata((prevFormData) => ({
      ...prevFormData,
      to_date: formattedDate,
    }));
  };

  const permissions = Object.freeze({
    add: checkPermission("64719f676a5e2082a24c0840", "add_permission"),
    edit: checkPermission("64719f676a5e2082a24c0840", "edit_permission"),
    view: checkPermission("64719f676a5e2082a24c0840", "view_permission"), // List of data must be true for other permissions
    delete: checkPermission("64719f676a5e2082a24c0840", "delete_permission"),
  });

  const customerStatusChange = (event) => {
    // alert()
    setcurrentStatus(event.target.innerHTML);
    fetchRequests(event.target.innerHTML);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    loadService(true);
    // fetchCustomers()
  }, []);

  const changeCustomers = (event) => {
    // alert(event.target.innerHTML)
    fetchCustomers(event.target.innerHTML);
  };

  const handleAutocompleteChange = (event, value) => {
    // handle change to Autocomplete value here
    // alert(" : "+value.value +"  =  "+value.label+" "+ event.target.innerHTML)
    setFormdata({ ...formData, ["current_status"]: value.label });
  };


  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
        const response = await axios.post(
            customersListUrl,
            formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        );
        console.log("response", response.status);
        setIsLoading(false);
        if (response.status === 200) {
            console.log("customers response", response.data.data)
            // let listItems = []
            // response.data.countires.map((item,index)=>{
            //   console.log(item)
            //   listItems.push({
            //     "id": index+1,
            //     "name":item
            //   })
            // })
            if (response.data.data) {
              const result = response.data.data
              console.log("cst", response.data.data);
              result.map((item) => {
                console.log("fn", item?.first_name);
                setCustomerNames(item?.first_name);
              } )
             
            }
            // alert(response.data.message);

        }
    } catch (error) {
        setIsLoading(false);
        toast.error("An error occurred while submitting the form");
        console.log(error);
        // alert("An error occurred");
    }
};

  const fetchRequests = async (page, page_limit) => {
    setIsLoading(true);
    try {
      //   const { access_token } = formData;
      const response = await axios.post(
        projectListUrl,
        JSON.stringify({
          page: page,
          page_limit: page_limit,
          //   access_token: access_token,
          access_token: localStorage.getItem("access_token"),
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response", response.status);
      setIsLoading(false);
      if (response.status === 200) {
        console.log("projects response", response.data.data);
        if (response.data.data) setResult(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const loadService = (isLoa = false) => {
    if (isLoa) {
      fetchRequests();
    }
  };

  const deleteRecordNow = async (rowId) => {
    let project_id = toast.loading("Please wait...");
    const response = await UseServices.fetchPostData(deleteProjectListUrl, {
      project_id: rowId,
    });
    const responseData = response;
    if (response.status === "valid") {
      // toast.success(responseData.message)
      toast.update(project_id, {
        render: responseData.message,
        autoClose: "3000",
        type: "success",
        isLoading: false,
      });
      loadService(true);
    } else {
      toast.error(responseData.message);
    }
    console.log(responseData.message);
  };

  //   <td>{app.project_date}</td>
  //                 <td>{app.gsas_project_id}</td>
  //                 <td>{app.project_name || "-"}</td>
  //                 <td>{app.plant_size || "-"}</td>
  //                 <td>{app.project_phase || "-"}</td>

  const columns = [
    { field: "id", headerName: "S.No." },
    {
      field: "Customer Details",
      headerName: "Customer Name",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.client_name || params.row.consultant_name || ""}`,
    },
    {
      field: "Customer Location",
      headerName: "Countrt & Location",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.country_id || ""}, ${params.row.city_id || ""}`,
    },
    { field: "project_date", headerName: "Project Date", flex: 1 },
    { field: "gsas_project_id", headerName: "GSAS Project ID", flex: 1 },
    { field: "project_name", headerName: "Project Name", flex: 1 },
    { field: "plant_size", headerName: "Plant Size (TR)", flex: 1 },
    { field: "project_phase", headerName: "Phase", flex: 1 },
    {
      field: "project_status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        if (params.row.project_status === "completed") {
          return (
            <Typography>
              <TaskAltIcon style={{ color: "green" }} />
            </Typography>
          );
        } else {
          return (
            <Typography>
              <HighlightOffSharpIcon style={{ color: "red" }} />
            </Typography>
          );
        }
      },
    },
    // { field: "created_at", headerName: "Created At", flex: 1,  },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      valueFormatter: (params) => {
        
        if(params.value){
          const timestamp = params.value;
          const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
  
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const hours = String(date.getHours()).padStart(2, "0");
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const seconds = String(date.getSeconds()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          return formattedDate;
        } else{
          return;
        }

      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Button
              component={Link}
              onClick={() =>
                navigate(
                  `/details?s=${params.row.project_status}&project_id=${params.row._id}`
                )
              }
              // onClick={() => navigate(`/details?s=${params.row.project_status}&project_id=${params.row.user_id.$oid}`)}
              variant="contained"
              color="secondary"
            >
              <VisibilityIcon />
            </Button>{" "}
            &nbsp;&nbsp;
            {permissions.delete && (
              <>
                <Button
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure Delete this "' +
                          params.row.project_name +
                          '"Project ?'
                      )
                    ) {
                      deleteRecordNow(params.row._id);
                    }
                  }}
                  variant="contained"
                  color="error"
                >
                  <DeleteIcon />
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // alert(name+" "+ value)
    setFormdata({ ...formData, [name]: value });
    console.log(formData);
  };
  //   const handleAutocompleteChange = (event, value) => {
  //     // handle change to Autocomplete value here
  //     // alert(" : "+value.value +"  =  "+value.label+" "+ event.target.innerHTML)
  //     setFormdata({ ...formData, ["current_status"]: value.label });
  //   };
  const resetForm = () => {
    setFormdata({
      access_token: getAccessToken(),
      from_date: "",
      to_date: "",
      current_stauts: "",
      search_key: "",
    });
    loadService(true);
  };
  return (
    <>
      {/* <pre style={{ color: "#fff" }}>
                {JSON.stringify(formData, null, 2)}
            </pre> */}
      <Box m="20px">
        <Grid container justify="center" spacing={0}>
          <Grid item lg={2} xs={12}>
            <Header title="Projects" subtitle="Managing the Projects List" />
          </Grid>

          {/* <Grid item lg={2} xs={12}>
            <InputLabel htmlFor="status">To Date</InputLabel>
            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              type="date"
              fullWidth
              sx={{ gridColumn: "span 2" }}
              name="to_date"
              value={formData.to_date.slice(0, 10)} // convert to ISO-8601 string
              onChange={handleInputChange} // update state with string value
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <InputLabel htmlFor="status">From Date</InputLabel>
            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              type="date"
              fullWidth
              sx={{ gridColumn: "span 2" }}
              name="from_date"
              value={formData.from_date.slice(0, 10)} // convert to ISO-8601 string
              onChange={handleInputChange} // update state with string value
            />
          </Grid> */}

<Grid sx={{ marginTop: "-8px" }}>
              <InputLabel htmlFor="status">From Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    onChange={handleInputChangeFromDates}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="from_date"
                        value={formData.from_date}
                        sx={{
                          height: "55px",
                          borderBottom: "1px solid #fff",
                          gridColumn: "span 4",
                          background: "#171717",
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            <Grid sx={{ marginTop: "-8px" }}>
              <InputLabel htmlFor="status">To Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    onChange={handleInputChangeToDates}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="to_date"
                        value={formData.to_date}
                        sx={{
                          height: "55px",
                          borderBottom: "1px solid #fff",
                          gridColumn: "span 4",
                          background: "#171717",
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          <Grid item lg={2} xs={12}>
            <InputLabel htmlFor="status">Search Here</InputLabel>
            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              fullWidth
              sx={{ gridColumn: "span 2" }}
              variant="outlined"
              type="text"
              name="search_key"
              value={formData.search_key}
              onChange={handleInputChange}
              autoComplete="off"
              placeholder="Search..."
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              // onChange={customerNames}
              options={result}
              sx={{ width: 200, marginTop: "20px" }}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  {...params}
                  label="Select Customers"
                />
              )}
            /> */}

            <Autocomplete
              disablePortal
              autoHighlight
              name="current_status"
              placeholder="Select Status"
              onChange={handleAutocompleteChange}
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              isOptionEqualToValue={(option, value) =>
                value && option.value === value.value
              }
              fullWidth
              sx={{ gridColumn: "span 2", marginTop: "20px" }}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item lg={2} xs={12} mt={1.6} p={1}>
            <Button
              style={{ padding: "14px  10px", margin: "0px" }}
              onClick={() => loadService(true)}
              variant="contained"
              color="success"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
            &nbsp;
            <Button
              style={{ padding: "14px  10px", margin: "0px" }}
              onClick={() => resetForm()}
              variant="outlined"
              color="error"
            >
              <LoopIcon />
            </Button>
          </Grid>
        </Grid>

        <Box
          m="0px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.redAccent} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
            "& .MuiCircularProgress-root": {
              color: "green",
            },
          }}
        >
          {/* <DataGrid loading={isLoading} rows={customerNames} columns={columns} /> */}
          <DataGrid
            loading={isLoading}
            rows={result}
            columns={columns}
            density={"compact"}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Projects;
