import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LoopIcon from "@mui/icons-material/Loop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useRef, useState } from "react";
import {
  BaseURL,
  allPackagesListUrl,
  deleteStaffUrl,
  packageCategoriesListUrl,
  staffListUrl,
  subTitleListUrl,
  titlePackagesListUrl,
} from "../../constants/globalUrls";
import DatePicker from "react-datepicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { toast } from "react-toastify";
import UseServices from "../../services/api";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CalendarViewDay } from "@mui/icons-material";
import dayjs from "dayjs";
import { getAccessToken } from "../../constants/helpers";
//   import StaffFormData from "./components/form_component";

const NewResult = () => {
  const currentDate = dayjs();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dataFetchedRef = useRef(false);
  const [currentMode, setCurrentMode] = useState("list"); //# view ,list, edit
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(dayjs("2022-04-17T15:30"));

  const [result, setResult] = useState([]);
  const [resultTitle, setTitleResult] = useState([]);
  const [subTitleResult, setSubTitleResult] = useState([]);
  const [resultCategory, setResultCategory] = useState([]);
  const [currentObj, setCurrentObj] = useState({
    question: "",
    answer: "",
    _id: "",
    id: "",
    from_date: "",
    to_date: "",
  });

  const [formData, setFormdata] = useState({
    from_date: currentDate,
    to_date: currentDate,
    user_wise: "",
  });

  const changeMainPackage = (event) => {
    fetchRequests(event.target.innerHTML);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // alert(name+" "+ value)
    setFormdata({ ...formData, [name]: value });
    console.log(formData);
  };

  const handleAutocompleteChange = (event, value) => {
    // handle change to Autocomplete value here
    // alert(" : "+value.value +"  =  "+value.label+" "+ event.target.innerHTML)
    setFormdata({ ...formData, ["current_status"]: value.label });
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // fetchRequests();
    loadService(true);
  }, []);
  const deleteRecordNow = async (rowId) => {
    let id = toast.loading("Please wait...");
    const response = await UseServices.fetchPostData(deleteStaffUrl, {
      id: rowId,
    });
    const responseData = response;
    if (response.status === "valid") {
      // toast.success(responseData.message)
      toast.update(id, {
        render: responseData.message,
        autoClose: "3000",
        type: "success",
        isLoading: false,
      });
      loadService(true);
    } else {
      toast.error(responseData.message);
    }
    console.log(responseData.message);
  };

  const fetchRequests = async (title = "", sub_title = "") => {
    try {
      const response = await axios.post(
        allPackagesListUrl,
        JSON.stringify({
          access_token: localStorage.getItem("access_token"),
          title: title,
          sub_title: sub_title,
        }),
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("response", response.status);
      if (response.status === 200) {
        console.log("response ch", response.data.data);
        console.log("response cate", response.data.data);
        setResult(response.data.data);
        setResultCategory(response.data.data.category)
      } else {
        toast.error("Something error occured. Try again..!");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const fetchPackageCategories = async () => {
    // let id = toast.loading("Please wait...", { theme: "dark" })
    const response = await UseServices.fetchPostData(packageCategoriesListUrl, { access_token: getAccessToken() });
    if (response.status === 'valid') {
      console.log("response:::::::::::::::");
      console.log(response.data);
      if (response.data) {
        let listItems = [];
        if (response.data !== "") {
          response.data.map((item, index) => {
            console.log(item)
            listItems.push({
              year: item.id,
              label: item.title,
            });
          });
          console.log("pc", listItems)
          setTitleResult(response.data);
        }
        // toast.update(id, { render: responseData.message, autoClose: "3000", theme: "dark", type: "success", isLoading: false });
      } else {
        // toast.update(id, { render: responseData.message, autoClose: "3000", theme: "dark", type: "error", isLoading: false });
      }
    }
  }

  const fetchSubCategories = async () => {
    // let id = toast.loading("Please wait...", { theme: "dark" })
    const response = await UseServices.fetchPostData(subTitleListUrl, { access_token: getAccessToken() });
    if (response.status === 'valid') {
      console.log("response:::::::::::::::");
      console.log(response.data);
      if (response.data) {
        let listItems = [];
        if (response.data !== "") {
          response.data.map((item, index) => {
            console.log(item)
            listItems.push({
              year: item.id,
              label: item.title,
            });
          });
          console.log("sc", listItems)
          setSubTitleResult(response.data);
        }
        // toast.update(id, { render: responseData.message, autoClose: "3000", theme: "dark", type: "success", isLoading: false });
      } else {
        // toast.update(id, { render: responseData.message, autoClose: "3000", theme: "dark", type: "error", isLoading: false });
      }
    }
  }


  const fetchMainPackage = async () => {
    try {
      const response = await axios.post(
        titlePackagesListUrl,
        JSON.stringify({ access_token: localStorage.getItem("access_token") })
      );
      console.log("response", response.status);
      if (response.status === 200) {
        console.log("response char", response.data.data);
        let listItems = [];
        if (response.data.data !== "") {
          response.data.data.map((item, index) => {
            // console.log(item)
            listItems.push({
              year: index + 1,
              label: item.title,
            });
          });

          setTitleResult(listItems);
        }
      } else {
        toast.error("Something error occured. Try again..!");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const loadService = (isLoa = false) => {
    // alert(isLoa)
    if (isLoa) {
      fetchRequests();
      fetchPackageCategories();
      fetchSubCategories()
    }
  };
  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "name", flex: 1 },

    { field: "email", headerName: "email", flex: 2 },
    { field: "mobile", headerName: "mobile", flex: 1 },
    { field: "enable_login", headerName: "enable_login", flex: 1 },
    {
      field: "role",
      headerName: "Role Name",
      flex: 1,
      valueGetter: (params) => `${params.row.role.name || ""}`,
    },
    { field: "created_at", headerName: "created_at", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
  ];

  return (
    <>
      {currentMode === "list" && (
        <Box m="20px">
          <Grid container justify="center" spacing={0}>
            <Grid item lg={4} xs={12}>
              <Header title="Result Page" subtitle="Managing the Result" />
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={0}>
            <Grid>
              <InputLabel htmlFor="status">From Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker value={formData.from_date} />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid>
              <InputLabel htmlFor="status">To Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker value={formData.from_date} />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            <Grid sx={{ marginTop: "27px" }} item lg={2} >
              <Autocomplete
                disablePortal
                clearOnBlur
                freeSolo
                id="combo-box-demo"
                // onChange={changeMainPackage}
                options={resultTitle}
                getOptionLabel={(option) => option.title}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField InputLabelProps={{
                    style: { color: "white" }
                  }} {...params} label="Select Package" />
                )}
                onChange={(event, value) =>{
                  // console.log(value._id)
                  if(value){
                    fetchRequests(value._id);
                  }
                  

                } } 
              />
            </Grid>

            <Grid sx={{ marginTop: "27px" }} item lg={2} >
              <Autocomplete
              disablePortal
              id="combo-box-demo"
              // onChange={changeSubPackages}
              options={subTitleResult}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField InputLabelProps={{
                  style: { color: "white" }
                }} {...params} label="Select Sub Categories" />
              )}
              onChange={(event, value) =>{
                // console.log(value._id)
                if(value){
                  fetchRequests(value._id);
                }
                

              } }
            />
            </Grid>

            <Grid item lg={3} xs={12} mt={2.5} p={1}>
              <Button
                style={{ padding: "14px  10px", margin: "0px" }}
                onClick={() => loadService(true)}
                variant="contained"
                color="success"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
              &nbsp;
            </Grid>
          </Grid>

          <Box
            m="0px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.redAccent} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              "& .MuiCircularProgress-root": {
                color: "green",
              },
            }}
          >
            {/* <DataGrid loading={isLoading} rows={result} columns={columns} density={"compact"} components={{ Toolbar: GridToolbar }} /> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default NewResult;
