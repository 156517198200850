import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function BarCharts() {
  const classes = useStyles();

  const [region, setRegion] = useState("");
  const [chartData, setChartData] = useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    setRegion(value);
  };

  const generateData = () => {
    const regions = ["Northeast", "Southwest", "West", "Southeast", "Midwest"];

    const result = [];

    regions.forEach((region) => {
      for (let i = 0; i < 11; i++) {
        const obj = {
          region: region,
          date: new Date(2021, i, 1).toDateString().slice(4),
          servers: Math.floor(Math.random() * 500) + 500,
          storage: Math.floor(Math.random() * 500) + 30,
        };
        result.push(obj);
      }
    });

    return result;
  };

  useEffect(() => {
    const data = generateData();
    setChartData(data.filter((x) => x.region === region));
  }, [region]);

  useEffect(() => {
    const defaultRegion = "Northeast"; // Change this to set your desired default region
    setRegion(defaultRegion);
  }, []);

  return (
    <div className="BarCharts">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <FormControl className={classes.formControl}>
              <InputLabel>Select Region</InputLabel>
              <Select
                value={region}
                id="regionSelector"
                name="region"
                onChange={handleChange}
              >
                <MenuItem value="Northeast">Northeast</MenuItem>
                <MenuItem value="Southwest">Southwest</MenuItem>
                <MenuItem value="West">West</MenuItem>
                <MenuItem value="Southeast">Southeast</MenuItem>
                <MenuItem value="Midwest">Midwest</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          <Paper className={classes.paper}>
            <ResponsiveContainer height={300}>
              <BarChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="servers" fill="#003366" />
                <Bar dataKey="storage" fill="#00887A" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default BarCharts;
