export function getCurrentDate(separator = '') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`

}
export function getAccessToken(){
    return localStorage.getItem("admin_access_token")
}
export function getPermissionsLocal(){
    return localStorage.getItem("permissions")
}
export function getRoleId(){
    return localStorage.getItem("role_id")
}

export function checkPermission(module_id, permission_type){
    const permissions = JSON.parse(localStorage.getItem("permissions_list"));

    if (permissions?.length > 0) {
        const matchingPermission = permissions.find(item => item["module_id"] === module_id);
        if (matchingPermission && matchingPermission[permission_type] === 1) {
          return true;
        }
      }
      return false;
}