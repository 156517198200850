import { Box, Button,useTheme,TextField, TextareaAutosize,FormHelperText, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio  } from "@mui/material";
import { Field, Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { addUpdateStaffUrl } from "../../../constants/globalUrls";
import UseServices from "../../../services/api"
import { toast } from "react-toastify";
import { tokens } from "../../../theme";

const FormData = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const {currentObj, modeSetUp,loadService} = props
    // console.log("c", props)
    const initialValues = {
        id: currentObj._id,
        name: currentObj.name,
        email:  currentObj.email,
        mobile:  currentObj.mobile,
        role_id:  currentObj.role_id,
        password:""
    };
    
    
    const handleFormSubmit = async (postData) => {
        console.log(postData)
        // return false
        let id = toast.loading("Please wait...",{theme:"dark"})
        const response = await UseServices.fetchPostData(addUpdateStaffUrl, postData);
        const responseData = response;
        if(response.status === 'valid'){
            // toast.success(responseData.message)
            toast.update(id, { render: responseData.message,autoClose:"3000",theme:"dark", type: "success", isLoading: false });
            loadService(true);
            modeSetUp("list");
        }else{
            toast.update(id, { render: responseData.message,autoClose:"3000",theme:"dark", type: "error", isLoading: false });
        }
        console.log(responseData.message);
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >
                        <input  type="hidden" value={initialValues._id} />
                        {values.name}dfds
                        <TextField  InputLabelProps={{
                                style: { color: "white" }
                            }}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            name="name"
                            error={!!touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField  InputLabelProps={{
                                style: { color: "white" }
                            }}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField  InputLabelProps={{
                                style: { color: "white" }
                            }}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Mobile"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.mobile}
                            name="mobile"
                            error={!!touched.mobile && !!errors.mobile}
                            helperText={touched.mobile && errors.mobile}
                            sx={{ gridColumn: "span 4" }}
                        />
                       
                        <TextField  InputLabelProps={{
                                style: { color: "white" }
                            }}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="New Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            helperText={"Note : Leave empty if not required"}
                            sx={{ gridColumn: "span 4" }}
                        />
                        {/* //image */}
                       
                        

                    </Box>
                    <Box display="flex" justifyContent="center" mt="20px">
                        {/* <Button type="button" onClick={() => modeSetUp("list")} color="info" variant="contained">
                            Back
                        </Button> &nbsp;&nbsp; */}
                        <Button type="submit" color="secondary" variant="contained">
                            Submit   
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    email: yup.string().required("required"),
    mobile: yup.string().required("required"),
    // role_id: yup.string().required("required"),
    enable_login: yup.string().required("required"),
    // is_deleted: yup.string().required("required"),

});


export default FormData;
