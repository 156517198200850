import { Box, Button, InputLabel, MenuItem, Select, TextField, TextareaAutosize } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { packageSubCategoriesAddUpdateUrl,packageCategoriesListUrl } from "../../../constants/globalUrls";
import UseServices from "../../../services/api"
import { toast } from "react-toastify";
import { useRef,useState ,useEffect} from "react";
import { getAccessToken } from "../../../constants/helpers";


const FormData = (props) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const {currentObj, modeSetUp,loadService,editPermission} = props 
    const [pakcageCategoriesList, setPakcageCategoriesList] = useState([])
    const dataFetchedRef = useRef(false);
    // console.log("c", props)
    const initialValues = {
        id: currentObj._id,
        title: currentObj.title,
        category_id:currentObj.category_id
    }; 
    
    useEffect(()=>{
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchPackageCategories();
    });

    const fetchPackageCategories = async () => {
        // let id = toast.loading("Please wait...", { theme: "dark" })
        const response = await UseServices.fetchPostData(packageCategoriesListUrl, { access_token: getAccessToken()});
        if (response.status === 'valid') {
            console.log("response:::::::::::::::");
            console.log(response.data);
            if(response.data){
                setPakcageCategoriesList(response.data)
            }
            // toast.update(id, { render: responseData.message, autoClose: "3000", theme: "dark", type: "success", isLoading: false });
        } else {
            // toast.update(id, { render: responseData.message, autoClose: "3000", theme: "dark", type: "error", isLoading: false });
        }


    };

    const handleFormSubmit = async (postData) => {
        console.log(postData);
        let id = toast.loading("Please wait...",{theme:"dark"})
        const response = await UseServices.fetchPostData(packageSubCategoriesAddUpdateUrl, postData);
        const responseData = response;
        if(response.status === 'valid'){
            // toast.success(responseData.message)
            toast.update(id, { render: responseData.message,autoClose:"3000",theme:"dark", type: "success", isLoading: false });
            loadService(true);
            modeSetUp("list");
        }else{
            toast.error(responseData.message)
        }
        console.log(responseData.message);
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >
                        <input  type="hidden" value={initialValues._id} />
                        <Select
                                defaultValue={"Select Package Category"}
                                value={values.category_id}
                                onChange={handleChange}
                                sx={{
                                    height:"55px",
                                borderBottom: "1px solid #fff",
                                gridColumn: "span 4",
                                background:"#171717"
                                }} 
                                name="category_id"
                                label="Select Package Category"
                            >
                                <MenuItem value="" selected  disabled>Select Package Category</MenuItem>
                                {pakcageCategoriesList &&  pakcageCategoriesList.map((cat) => {
                                    return <MenuItem key={cat._id} value={cat._id}>{cat.title}</MenuItem>;
                                    })}
                            </Select>
                        <TextField  InputLabelProps={{
                                style: { color: "white" }
                            }}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            name="title"
                            error={!!touched.title && !!errors.title}
                            helperText={touched.title && errors.title}
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="center" mt="20px">
                        <Button type="button" onClick={() => modeSetUp("list")} color="info" variant="contained">
                            Back
                        </Button> &nbsp;&nbsp;
                        {/* <Button type="submit" color="secondary" variant="contained">
                            Submit   
                        </Button> */}

                        {editPermission && <>
                            <Button type="submit" color="secondary" variant="contained">
                            Submit   
                        </Button>
                        </>}
                    </Box>
                </form>
            )}
        </Formik>
    );
};

const checkoutSchema = yup.object().shape({
    title: yup.string().required("required"),
});


export default FormData;
